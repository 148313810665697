import React from "react"
import isNil from "lodash/isNil"

import COPY from "~config/copy-constants"
import LoadingSkeleton from "~components/shared/loading-skeleton"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"
import { useTheme } from "~config/theme"
import type {
    ListingMinimalFieldsFragment,
    PublicListingFieldsFragment,
} from "~graphql/generated/graphql"
import { formatPrice } from "~utils/helpers"

export type ListingForPrice =
    | Pick<ListingMinimalFieldsFragment, "id" | "lowest_pricing_package">
    | Pick<PublicListingFieldsFragment, "id" | "lowest_pricing_package">
    | null
    | undefined

interface Props {
    readonly listing: ListingForPrice
    readonly isLoading?: boolean
}

export default function Price({ isLoading, listing }: Props) {
    const styles = useStyles()

    if (isLoading) {
        return (
            <div className={styles.loader}>
                <LoadingSkeleton />
            </div>
        )
    }
    if (!listing) return null

    const hasPrice = !isNil(listing.lowest_pricing_package)
    const lowestPrice = formatPrice(listing.lowest_pricing_package?.price)

    return (
        <div className={styles.root}>
            <ConditionalRenderContainer shouldRender={hasPrice}>
                <span>{COPY.STARTING_AT}</span>
                <span className={styles.amount}>{lowestPrice}</span>
            </ConditionalRenderContainer>
        </div>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        root: css({
            height: "20px",
            fontWeight: 300,
        }),
        amount: css({
            fontSize: "17px",
            fontWeight: 700,
            marginLeft: theme.sizing.scale200,
        }),
        loader: css({
            width: "35%",
            height: "20px",
            maxWidth: "200px",
        }),
    }
}
