import type { PropsWithChildren } from "react"
import React from "react"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"

import ResponsiveBlock from "~components/shared/responsive-block"
import PageSectionHeading from "~components/shared/typography/page-section-heading"
import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import { useTheme } from "~config/theme"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
}

export default function GenericCollectionGrid({
    label,
    buttonLabel,
    buttonUrl,
    children,
}: Props) {
    const styles = useStyles()
    const childrenAsArray = Array.isArray(children) ? children : [children]

    return (
        <>
            <ResponsiveBlock>
                {label && <PageSectionHeading label={label} />}
                <FlexGrid
                    flexGridRowGap="scale900"
                    flexGridColumnGap="scale800"
                    flexGridColumnCount={[1, 2, 2, 3, 4]}
                >
                    {childrenAsArray.map((child, index) => {
                        return <FlexGridItem key={index}>{child}</FlexGridItem>
                    })}
                </FlexGrid>
            </ResponsiveBlock>

            <div className={styles.buttonContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </div>
        </>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        buttonContainer: css({
            textAlign: "center",
            marginTop: theme.sizing.scale800,
        }),
    }
}
