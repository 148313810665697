import React, { useContext } from "react"
import { useStyletron } from "baseui"

import { UserContext } from "~config/user-provider"
import { useAuth } from "~utils/auth-hooks"

export default function ImpersonationBox() {
    const styles = useStyles()
    const { authUser } = useAuth()
    const { user, isImpersonating } = useContext(UserContext)

    if (!user || !isImpersonating) return null

    return (
        <div className={styles.root}>
            <div className={styles.box}>
                <p className={styles.text}>
                    Logged in as {authUser?.name}&nbsp;
                    <strong>({authUser?.email})</strong>
                </p>
                <p className={styles.text}>
                    Now viewing as&nbsp;
                    <u>
                        {user.first_name} {user.last_name}
                    </u>
                    &nbsp;
                    <strong>({user.email})</strong>
                </p>
            </div>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            width: "100%",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: "72px",
            [theme.mediaQuery.medium]: {
                bottom: "24px",
            },
        }),
        box: css({
            margin: "0 8px",
            color: "white",
            backgroundColor: "rgba(76, 14, 0, 0.55)",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            boxShadow: theme.lighting.shadow700,
            borderRadius: "48px",
            padding: "8px 24px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            [theme.mediaQuery.medium]: {
                minWidth: "300px",
            },
        }),
        text: css({
            fontSize: "13px",
            margin: `${theme.sizing.scale100} 0`,
            [theme.mediaQuery.medium]: {
                fontSize: "14px",
            },
        }),
    }
}
