import React from "react"
import type { WrapRootElementNodeArgs } from "gatsby"
import { navigate } from "gatsby-link"
import { Auth0Provider } from "@auth0/auth0-react"
import { polyfill } from "interweave-ssr"
import "mapbox-gl/dist/mapbox-gl.css"

// Needed for react-slick: https://github.com/akiran/react-slick?tab=readme-ov-file#installation
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ApolloProvider from "~apollo/provider"
import { UserProvider } from "~config/user-provider"
import { ResponsiveProvider } from "~config/responsive-provider"
import { ReactQueryProvider } from "~config/react-query-provider"
import { isServerSide } from "~utils/helpers"
import { AUTH_REDIRECT_ROUTE } from "~config/constants"
import { initBuilderIo } from "~utils/builder-io/builder-io-helpers"
import CourierInboxProvider from "~config/courier-inbox-provider"

polyfill()

initBuilderIo()

export default function WrapRootElement({
    element,
}: Readonly<WrapRootElementNodeArgs>) {
    const redirectUri = !isServerSide()
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix eslint
          `${window?.location.origin}${AUTH_REDIRECT_ROUTE}`
        : ""

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN!}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: process.env.GATSBY_AUTH0_AUDIENCE,
            }}
            onRedirectCallback={(appState) => {
                void navigate(appState?.returnTo || window.location.pathname, {
                    replace: true,
                })
            }}
            cacheLocation="localstorage"
        >
            <ApolloProvider>
                <ReactQueryProvider>
                    <ResponsiveProvider>
                        <UserProvider>
                            <CourierInboxProvider>
                                {element}
                            </CourierInboxProvider>
                        </UserProvider>
                    </ResponsiveProvider>
                </ReactQueryProvider>
            </ApolloProvider>
        </Auth0Provider>
    )
}
